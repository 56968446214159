import React, { useState, useEffect, useMemo, useCallback } from "react";
import { GlobalStyles } from "../../../styles/global";

const ChildComponent = ({ childPropA }) => {
  const [useEffectCount, setUseEffectCount] = useState(0);
  useEffect(() => {
    setUseEffectCount((count) => count + 1);
    // Hook dependencies
  }, [childPropA]);
  return <div>useEffectCount: {useEffectCount}</div>;
};

const ParentComponent = () => {
  const isExcited = true;
  const propA = useMemo(() => {
    showExcitement: isExcited;
  }, [isExcited]);

  const [demoState, setDemoState] = useState(1);
  return (
    <>
      <ChildComponent childPropA={propA} />
      <button onClick={() => setDemoState((v) => v + 1)}>
        Re-render ParentComponent (demoStateCount: {demoState}x)
      </button>
    </>
  );
};

export const RefEqNoMemoDemo = () => {
  const [showDemo, setShowDemo] = useState(false);
  const demoStyle = {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#aaa",
  };
  return (
    <div>
      <GlobalStyles></GlobalStyles>
      {showDemo ? (
        <ParentComponent></ParentComponent>
      ) : (
        <div style={demoStyle}>
          <button onClick={(e) => setShowDemo(true)}>
            Click to Enable Demo
          </button>
        </div>
      )}
    </div>
  );
};

export default RefEqNoMemoDemo;
